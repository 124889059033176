<template>
    <div class="toggle_btn_link mr-3">
      <div class="toggle_btn_inner">
        <a class="btn_link" @click="changeAnalytics(analytic_types.LINK)"
           :class="{'active_tab':selected_analytic_type === analytic_types.LINK }">
          {{ getSiteDetails.agency_name }} Links
        </a>
        <a class="btn_link" @click="changeAnalytics(analytic_types.BIO_LINK)"
           :class="{'active_tab':selected_analytic_type === analytic_types.BIO_LINK }">
          Bio Links
        </a>
      </div>
    </div>
</template>
<script>
import {ANALYTIC_TYPE} from "@/common/constants";

export default {
  props: {
    value: {
      type: String,
      default: ANALYTIC_TYPE.LINK
    }
  },
  data(){
    return {
      analytic_types: ANALYTIC_TYPE
    }
  },
  computed: {
    selected_analytic_type(){
      return this.value
    }
  },
  created() {
    const analyticsType = this.fetchAnalyticType()
    this.changeAnalytics(analyticsType)
  },
  methods: {
    fetchAnalyticType() {
      if (this.$route.query.hasOwnProperty('analytic_type')) {
        const analytic_type = this.$route.query.analytic_type;
        // selected analytic type is not one of the replug links or bio links
        if (analytic_type === ANALYTIC_TYPE.BIO_LINK) {
          return ANALYTIC_TYPE.BIO_LINK;
        }
      }

      return ANALYTIC_TYPE.LINK;
    },
    changeAnalytics(analyticsType) {
      if (Object.values(ANALYTIC_TYPE).indexOf(analyticsType) === -1) {
        analyticsType = ANALYTIC_TYPE.LINK
      }
      if(this.fetchAnalyticType() !== analyticsType){
        const query = {query: {...this.$route.query, analytic_type: analyticsType }};
        this.$router.replace(query)
      }
      this.$emit('input', analyticsType)
    },
  }
}
</script>
