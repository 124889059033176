<template>
  <b-dropdown right class="radio_dropdown dropdown-menu-right default_style_dropdown" ref="dropdown" :no-caret="true">
    <!--d-flex align-items-center-->
    <div @click="fetchBrandOnDropdown" class="text-center dropdown_header  rounded_shape d-flex align-items-center"
         slot="button-content">
      <div class="text">

        <span v-if="getAnalyticsFilters.brand.name">{{ limitTextLength(getAnalyticsFilters.brand.name, 20) }}</span>
        <span v-else>Select Brand</span>

      </div>
      <span class="arrow_icon ml-auto">
                    <i class=" fal fa-angle-down ml-3"></i>
                </span>
    </div>
    <ul id="brand-filter-dropdown-scroll"
        @scroll="lazyScrollBrand('brand-filter-dropdown-scroll','fetchBrandList',1)" class="clear inner">

      <div v-if="getBrandList && getBrandList.length && getBrandList.length > 7 || getBrand.search"
           class="search_input_block no_animation mr-2 ml-2 ">
        <input class="full-width" style="font-size:13px;" type="text" @input="debounceBrandDropdownSearch"
               :value="getBrand.search"
               placeholder="Search by keyword...">

        <i class="fal fa-search"></i>
      </div>

      <template v-if="getBrandList && getBrandList.length">
        <li class="list_item_li" v-for="brand in getComponentBrandList">
          <div class="radio_input d-flex justify-content-between" @click="selectItem(brand)">
            <label>
              <div class="d-flex align-items-center profile__box">
                <div class="avatar">
                  <div class="brand_img" :style="brandImageLink(brand.avatar)"></div>
                </div>
                <div class="content">
                  <div class="name">
                    {{ limitTextLength(brand.name, 21) }}
                  </div>
                </div>
              </div>
            </label>
            <i class="far fa-check-circle checked" v-if="brand._id == getAnalyticsFilters.brand._id"></i>
            <i class="far fa-circle" v-else></i>
          </div>
        </li>
        <li v-if="getBrandLoaders.retrieve" class="list_item_li">
          <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
        </li>
      </template>
      <li v-else-if="getBrandLoaders.retrieve" class="list_item_li">
        <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
      </li>
      <li v-if="!getBrandLoaders.retrieve && !getComponentBrandList.length && getBrand.search.length"
          class="no_text_li">{{ searchFiltersMessages('').search_field_dropdown }}
      </li>
      <li class="list_item_li no_text_li mt-2" v-else-if="!getBrandLoaders.retrieve && !getBrandList.length">
        No brands found.
      </li>
    </ul>
  </b-dropdown>
</template>
<script>
import { mapGetters } from 'vuex'
import { searchFiltersMessages } from '@/common/attributes'

export default ({

  data () {
    return {
      searchFiltersMessages: searchFiltersMessages
    }
  },
  computed: {
    ...mapGetters([
      'getAnalyticsDetail',
      'getComponentBrandList',
      'getAnalyticsFilters',
      'getBrandLoaders',
      'getBrandList'
    ])
  },
  methods: {
    selectItem (brand) {
      this.getAnalyticsFilters.brand = brand
      this.$refs.dropdown.hide()
      this.getAnalyticsFilters.campaign.name = null
      this.getAnalyticsFilters.campaign._id = null
      this.getAnalyticsFilters.link.shorten_url = null
      this.getAnalyticsFilters.link._id = null
      this.getAnalyticsFilters.bio_link._id = null
      this.getAnalyticsFilters.bio_link.shorten_url = null
      this.$parent.countTotalLinks(brand._id, null)
    },
    selectBrand (brand) {

    }
  }

})
</script>

<style scopped lang="less">
.radio_input {
  padding: 7px 15px;

  .avatar {
    .brand_img {
      width: 20px;
      height: 20px;
      border-radius: 3px;
    }
  }

  i {
    margin-top: 7px;
  }

  .checked {
    color: #2dd678;
  }
}
</style>
