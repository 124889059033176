<template>
  <div class="analytic_component">
    <transition name="slide">
      <!--<sidebar></sidebar>-->

      <div class="container ">
        <div class="analytic_component_inner">
          <div class="component_head d-flex align-items-center">
            <div class="head_left">
              <analytics-toggle-btn></analytics-toggle-btn>
            </div>
            <div class="head_right ml-auto d-flex align-items-center justify-content-end">
              <analytics-type v-model="selected_analytic_type"></analytics-type>
              <div
                class=" datepicker_field ml-2 rounded input_field datepicker_input datePicker_parent input_with_icon_right">
                <input v-model="getAnalyticsFilters.date.label" readonly placeholder="Search by date..."
                       type="text" class=" detailViewDatePicker">
                <i class="input_icon fal fa-angle-down"></i>
              </div>
            </div>
            <!--            <div class="head_right ml-auto d-flex align-items-center justify-content-end">

                          <div class="toggle_btn_link mr-3">
                            <div class="toggle_btn_inner">
                              <a class="btn_link" @click="changeAnalytics(analytic_types.LINK)"
                                 :class="{'active_tab':selected_analytic_type === analytic_types.LINK }">
                                {{ getSiteDetails.name }} Links
                              </a>
                              <a class="btn_link" @click="changeAnalytics(analytic_types.BIO_LINK)"
                                 :class="{'active_tab':selected_analytic_type === analytic_types.BIO_LINK }">
                                Bio Links
                              </a>

                            </div>
                          </div>

                          <div
                            class=" datepicker_field ml-2 rounded input_field datepicker_input datePicker_parent input_with_icon_right">
                            <input v-model="getAnalyticsFilters.date.label" readonly placeholder="Search by date..."
                                   type="text" class=" detailViewDatePicker">
                            <i class="input_icon fal fa-angle-down"></i>
                          </div>

                        </div>-->

          </div>

          <div class="component_head component_head_bottom d-flex align-items-center">
            <div class="head_left d-flex align-items-center">
              <analytics-brands-filter></analytics-brands-filter>
              <analytics-campaigns-filter
                v-if="selected_analytic_type === analytic_types.LINK"></analytics-campaigns-filter>
              <analytics-links-filter v-if="selected_analytic_type === analytic_types.LINK"
                                      ref="analytics_links_filter"></analytics-links-filter>
              <analytics-bio-links-filter v-if="selected_analytic_type === analytic_types.BIO_LINK"
                                          ref="analytics_bio_links_filter"></analytics-bio-links-filter>

              <button class="ml-2 btn---cta btn-blue with-shadow btn-round   btn-bold"
                      v-if="getAnalyticsFilters.apply"
                      @click="applyAnalyticsFilter()">
                <!-- @click.prevent="changeSelectionFilter()"-->
                <span>Apply</span>
                <span class="icon_right">
                                       <i
                                         class="circle_icon d-flex align-items-center justify-content-center  fal fa-angle-right  "></i>
                                </span>
              </button>

            </div>
            <div class="head_right ml-auto d-flex align-items-center justify-content-end">

              <b-dropdown ref="analyticCategoryDropdown" right
                          class="ml-2  dropdown-menu-right default_style_dropdown"
                          :no-caret="true">
                <!--d-flex align-items-center-->
                <div class="text-center dropdown_header  rounded_shape  d-flex align-items-center"
                     slot="button-content">
                  <div class="text">
                    <p class="text-capitalize">{{
                        getAnalyticsFilters.view_filter.replace("_",
                          " ")
                      }}</p>
                  </div>
                  <span class="arrow_icon ml-auto">
                                                <i class=" fal fa-angle-down ml-3"></i>
                                            </span>
                </div>
                <ul class="clear inner">

                  <li class="list_item_li"
                      @click.prevent="$store.commit('SET_ANALYTICS_VIEW_FILTER', 'overview'),$refs.analyticCategoryDropdown.hide(true)">
                    <i class="fal fa-pie-chart mr-2"></i> Overview

                  </li>

                  <li class="list_item_li"
                      @click.prevent="$store.commit('SET_ANALYTICS_VIEW_FILTER', 'country'),$refs.analyticCategoryDropdown.hide(true)">
                    <i class="fal fa-globe mr-2"></i> Country

                  </li>
                  <li class="list_item_li"
                      @click.prevent="$store.commit('SET_ANALYTICS_VIEW_FILTER', 'browsers'),$refs.analyticCategoryDropdown.hide(true)">
                    <i class="fal fa-window-maximize mr-2"></i> Browsers

                  </li>

                  <li class="list_item_li"
                      @click.prevent="$store.commit('SET_ANALYTICS_VIEW_FILTER', 'operating_system'),$refs.analyticCategoryDropdown.hide(true)">
                    <i class="fal fa-laptop mr-2"></i> Operating System
                  </li>
                  <li class="list_item_li"
                      @click.prevent="$store.commit('SET_ANALYTICS_VIEW_FILTER', 'all_visitors'),$refs.analyticCategoryDropdown.hide(true)">
                    <i class="fal fa-th mr-2"></i> All visitors
                  </li>

                </ul>
              </b-dropdown>

            </div>

          </div>

          <template v-if="getAnalyticsFilters.view_filter === 'overview'">
            <template v-if="!loader">
              <overview-of-analytics :statistics="statistics" :analytics_type="selected_analytic_type"
                                     :loader="loader"></overview-of-analytics>
            </template>
            <template v-else>
              <clip-loader :color="'#0095f3'" :size="size"></clip-loader>
              <!--              <beat-loader :color="color"></beat-loader>-->
            </template>

          </template>
          <template v-if="getAnalyticsFilters.view_filter !== 'overview'">
            <div class="white_box">
              <div class="box_inner">
                <div class="box_head d-flex align-items-center">
                  <div class="left">
                    <h2>By
                      <span class="text-capitalize">
                                                {{ getAnalyticsFilters.view_filter.replace("_", " ") }}
                                            </span>
                    </h2>
                  </div>
                  <div class="right  ml-auto">
                    <p>Total visitors: <strong>{{ statistics.hits.total }}</strong></p>
                  </div>
                </div>
                <div class="box_content">
                  <template v-if="getAnalyticsFilters.view_filter === 'country'">
                    <countries-table-list :statistics="statistics"
                                          :forBio="selected_analytic_type === analytic_types.BIO_LINK"
                                          :loader="loader"></countries-table-list>
                  </template>
                  <template v-if="getAnalyticsFilters.view_filter === 'browsers'">
                    <browsers-table-list :statistics="statistics"
                                         :forBio="selected_analytic_type === analytic_types.BIO_LINK"
                                         :loader="loader"></browsers-table-list>
                  </template>
                  <template v-if="getAnalyticsFilters.view_filter === 'operating_system'">
                    <os-table-list :statistics="statistics" :forBio="selected_analytic_type === analytic_types.BIO_LINK"
                                   :loader="loader"></os-table-list>
                  </template>
                  <template v-if="getAnalyticsFilters.view_filter === 'all_visitors'">
                    <realtime-analytics ref="realtime" :loader="loader"
                                        :forBio="selected_analytic_type === analytic_types.BIO_LINK"></realtime-analytics>
                  </template>
                </div>
              </div>
            </div>
          </template>

        </div>

      </div>

    </transition>
  </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

</style>

<script>

import {mapActions, mapGetters} from 'vuex'
import AnalyticsToggleBtn from './AnalyticsToggleBtn.vue'
import Dropdown from '@/ui/Dropdown.vue'
import AnalyticsBrandsFilter from './filters/AnalyticsBrandsFilter'
import AnalyticsCampaignsFilter from './filters/AnalyticsCampaignsFilter'
import AnalyticsLinksFilter from './filters/AnalyticsLinksFilter'
import AnalyticsBioLinksFilter from './filters/AnalyticsBioLinksFilter'
import AnalyticsType from './AnalyticsType'
import {analyticsTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from "@/common/constants";
import {ANALYTIC_TYPE} from "../../../common/constants";

const OverviewOfAnalytics = () => import('./detail-view/OverviewOfAnalytics')
const CountriesTableList = () => import('./detail-view/tables/CountriesTableList')
const BrowsersTableList = () => import('./detail-view/tables/BrowsersTableList')
const OSTableList = () => import('./detail-view/tables/OSTableList')
const RealTimeAnalytics = () => import('./detail-view/RealTimeAnalytics')

//    import AnalyticAll from './AnalyticAll.vue';
//    import AnalyticBrowsers from './AnalyticBrowsers.vue';
//    import AnalyticOS from './AnalyticOS.vue';
//    import AnalyticDevices from './AnalyticDevices.vue';

export default ({
  'name': 'AnalyticsDetail',
  components: {
    Dropdown,
    AnalyticsToggleBtn,
    AnalyticsBrandsFilter,
    AnalyticsCampaignsFilter,
    AnalyticsLinksFilter,
    AnalyticsBioLinksFilter,
    AnalyticsType,
    OverviewOfAnalytics,
    CountriesTableList,
    BrowsersTableList,
    'os-table-list': OSTableList,
    'realtime-analytics': RealTimeAnalytics,
  },
  data() {
    return {
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      currentTab: 'tab1',
      apply_filter: false,
      statistics: {},
      loader: true,
      isPermission: false,
      selected_analytic_type: ANALYTIC_TYPE.LINK,
      analytic_types: ANALYTIC_TYPE
    }
  },
  mounted() {
    if (window.localStorage.getItem('access_password_protected') === 'true') {
      this.isPermission = true
    }

    let self = this
    setTimeout(function () {
      self.callDetailViewDatePicker()
    }, 0)
  },
  async created() {
    this.$store.commit('SET_ANALYTICS_VIEW_FILTER', 'overview')
    // this.fetchDetailViewOfAnalytics()
  },

  computed: {

    ...mapGetters([
      'getAnalyticsDetail',
      'getBrands',
      'getAnalyticsFilters'
    ])

  },

  methods: {

    ...mapActions([
      'setAllBrandsFetchStatus',
      'bioRealtimeStatistics',
      'countLinks',
      'countBioLinks'
    ]),
    applyAnalyticsFilter() {
      if (this.getAnalyticsFilters.view_filter === 'all_visitors') {
        this.$refs.realtime.fetchRealtimeStatistics()
        return;
      }

      this.fetchDetailViewOfAnalytics()
    },
    async fetchDetailViewOfAnalytics() {
      this.loader = true
      const type = this.selected_analytic_type === ANALYTIC_TYPE.LINK ? 'detailViewStatistics' : 'bioDetailViewStatistics';
      let res = await this.$store.dispatch(type)
      if (res && res.data.status) {
        this.statistics = res.data.data
      }
      this.loader = false
    },
    callDetailViewDatePicker() {
      const startDate = moment().subtract(29, 'days')
      const endDate = moment()
      let me = this
      $(document).ready(function () {
        const cb = (startDate, endDate, label) => {
          let range = {label: "All Time", value: ""}
          if (label !== " All Time") {
            range = {
              label,
              value: startDate.format('YYYY-MM-DDTHH:mm:ss') + ' _ ' + endDate.format('YYYY-MM-DDTHH:mm:ss')
            }
          }
          // calling the content mixing method to reflect the range filter.
          me.$store.commit(analyticsTypes.SET_ANALYTICS_DATE, range)
          if (me.getAnalyticsFilters.view_filter === 'all_visitors') {
            me.$refs.realtime.fetchRealtimeStatistics()
          } else {
            me.fetchDetailViewOfAnalytics()
          }
        }

        $('.detailViewDatePicker').daterangepicker({
          opens: 'left',
          maxDate: moment(),
          autoUpdateInput: false,
          startDate,
          endDate,
          ranges: {
            ' All Time': [moment().startOf('day').subtract(1, 'hour'), moment().endOf('day').subtract(1, 'hour')],
            ' Today': [moment(), moment()],
            ' Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            ' Last 7 Days': [moment().subtract(6, 'days'), moment()],
            ' Last 30 Days': [moment().subtract(29, 'days'), moment()],
            ' This Month': [moment().startOf('month'), moment().endOf('month')],
            ' Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            ' Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
          }
        }, cb)
        cb(startDate, endDate, ' Last 30 Days')
      })
    },
    countTotalLinks(brand, cta) {
      const payload = {
        brand_id: brand,
        call_to_action_id: cta
      }
      const countFn = this.selected_analytic_type === ANALYTIC_TYPE.LINK ? 'countLinks' : 'countBioLinks'
      const res = this.$store.dispatch(countFn, payload)
      res.then(val => {
        this.statistics.account_statistics.links = val.data.data
      })
    }

  },
  watch: {
    'getAnalyticsFilters.brand._id'(value) {
      this.$set(this.getAnalyticsFilters, 'apply', true)
      if (this.selected_analytic_type === this.analytic_types.BIO_LINK) {
        this.$refs.analytics_bio_links_filter.searchLinksDirectly()
      }
    },
    'getAnalyticsFilters.campaign._id'(value) {
      this.$set(this.getAnalyticsFilters, 'apply', true)
      this.$refs.analytics_links_filter.searchLinksDirectly()
    },
    'getAnalyticsFilters.link._id'(value) {
      this.$set(this.getAnalyticsFilters, 'apply', true)
    },
    'selected_analytic_type'() {
      this.applyAnalyticsFilter()
    }
  }
})
</script>
