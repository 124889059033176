<template>
  <b-dropdown :disabled="!getAnalyticsFilters.brand._id" right ref="dropdown"
              class="ml-2 radio_dropdown dropdown-menu-right default_style_dropdown"
              :no-caret="true">
    <!--d-flex align-items-center-->
    <div @click="fetchCampaignOnDropdown" class="text-center dropdown_header  rounded_shape d-flex align-items-center"
         slot="button-content">
      <div class="text">

        <span
            v-if="getAnalyticsFilters.campaign.name">{{ limitTextLength(getAnalyticsFilters.campaign.name, 20) }}</span>
        <span v-else>Select Campaign</span>

      </div>
      <span class="arrow_icon ml-auto">
                    <i class=" fal fa-angle-down ml-3"></i>
                </span>
    </div>
    <ul id="campaign-dropdown-scroll"
        @scroll="lazyScroll('campaign-dropdown-scroll','fetchCampaignsList')" class="clear inner">

      <div class="search_input_block no_animation mr-2 ml-2 ">
        <input class="full-width" style="font-size:13px;" type="text"
               :value="getCampaigns.search" @input="debounceCampaignDropdownSearch" placeholder="Search by keyword...">

        <i class="fal fa-search"></i>
      </div>

      <template v-if="getCampaignsList && getCampaignsList.length">
        <li class="list_item_li" v-for="campaign in getComponentCampaignList">
          <div class="radio_input d-flex justify-content-between" @click="selectItem(null, campaign)">
            <label>
              <div class="d-flex align-items-center profile__box">
                <p>{{ limitTextLength(campaign.name, 21) }}</p>
              </div>
            </label>
            <i class="far fa-check-circle checked" v-if="campaign._id == getAnalyticsFilters.campaign._id"></i>
            <i class="far fa-circle" v-else></i>
          </div>
        </li>
        <li v-if="getCampaignLoaders.retrieve" class="list_item_li">
          <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
        </li>
      </template>
      <li v-else-if="getCampaignLoaders.retrieve" class="list_item_li">
        <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
      </li>
      <li v-if="!getCampaignLoaders.retrieve && !getComponentCampaignList.length && getCampaigns.search.length" class=" no_text_li">Your brand does not have any campaigns.</li>
      <li v-else-if="!getCampaignLoaders.retrieve && !getCampaignsList.length" class=" no_text_li">{{ searchFiltersMessages('campaigns').empty_list_dropdown }}</li>
    </ul>
  </b-dropdown>
</template>
<script>
import { mapGetters } from 'vuex'

export default ({
  data () {
    return {
      search_campaign: ''
    }
  },
  created () {
    // this.getAnalyticsFilters.campaign.name = null
  },
  computed: {
    ...mapGetters([
      'getAnalyticsFilters',
      'getComponentCampaignList',
      'getCampaigns',
      'getCampaignLoaders',
      'getCampaignsList'
    ])
  },
  methods: {
    selectItem (brand, cta) {
      this.getAnalyticsFilters.campaign = cta
      this.$refs.dropdown.hide()
      this.getAnalyticsFilters.link.shorten_url = null
      this.getAnalyticsFilters.link._id = null
      this.$parent.countTotalLinks(this.getAnalyticsFilters.brand._id, cta._id)
    }
  }

})
</script>

<style scopped lang="less">
.radio_input {
  padding: 7px 15px;

  .avatar {
    .brand_img {
      width: 20px;
      height: 20px;
      border-radius: 3px;
    }
  }

  i {
    margin-top: 7px;
  }
  .checked {
    color: #2dd678;
  }
}
</style>
