<template>
    <div class="toggle_btn_link">
        <div class="toggle_btn_inner">
             <router-link class="btn_link " :to="{'name': 'analytics'}" :class="{'active_tab':$route.name === 'analytics' }">
                List View
            </router-link>

            <!--<a  href="javascript:;" class="btn_link "-->
            <!--:class="{'active_tab':this.$router.name=='analytics_detail'}"-->
            <!--@click.prevent="getAnalyticsDetailView()"> Detail View</a>-->
            <a @click.prevent="navigateToDetailView()" class="btn_link" :class="{'active_tab':$route.name === 'analytics_detail' }">
                Detailed View
            </a>

        </div>
    </div>
</template>
<script>
import { analyticsTypes } from '@/state/modules/mutation-types'

export default {
  mounted() {
  },
  methods: {
    navigateToDetailView () {
      this.$store.commit(analyticsTypes.RESET_ANALYTICS_LINK_FILTER)
      this.$store.commit(analyticsTypes.RESET_ANALYTICS_BRAND_FILTER)
      this.$store.commit(analyticsTypes.RESET_ANALYTICS_CAMPAIGN_FILTER)
      this.$router.push({ name: 'analytics_detail' })
    }
  }
}
</script>
<style lang="less">
.toggle_btn_inner{
  width: 100% !important;
}
</style>
