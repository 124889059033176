<template>
    <b-dropdown :disabled="!getAnalyticsFilters.brand._id" right
                class="ml-2 radio_dropdown dropdown-menu-right default_style_dropdown"
                :no-caret="true"
                ref="dropdown"
    >
        <!--d-flex align-items-center-->
        <div class="text-center dropdown_header  rounded_shape d-flex align-items-center"
             slot="button-content">
            <div class="text">
                <span v-if="getAnalyticsFilters.bio_link.shorten_url">{{limitTextLength(getAnalyticsFilters.bio_link.shorten_url,20)}}</span>
                <span v-else>Select Link</span>

            </div>
            <span class="arrow_icon ml-auto">
                                                <i class=" fal fa-angle-down ml-3"></i>
                                            </span>
        </div>
        <ul class="clear inner">

            <div class="search_input_block no_animation ml-2 mr-2 ">
                <input class="full-width" style="font-size:13px;" type="text" v-model="search_link"
                       placeholder="Search by url/slug"
                       @keyup="getAnalyticsLinkSearch(search_link)">

                <i class="fal fa-search"></i>
            </div>

            <template>
                <template v-if="loader">
                    <clip-loader :size="size" :color="color"></clip-loader>
                </template>
                <template v-if="getAnalyticsFilteredLinks && getAnalyticsFilteredLinks.length > 0 && !loader">
                    <li class="list_item_li" v-for="link in getAnalyticsFilteredLinks">

                        <div class="radio_input_image " @click="$refs.dropdown.hide()">
                            <input type="radio" name=""
                                   :id="link._id" :value="link"
                                   v-model="getAnalyticsFilters.bio_link"
                                   class="hide">

                            <label :for="link._id" class="radio_right">
                                <template>
                                    <p class="" style="word-break: break-all;">
                                        <span
                                                v-if="link.title">
                                            {{limitTextLength(link.title, 20)}}
                                            <br>
                                        </span>
                                        https://{{link.shorten_domain}}/{{link.shorten_url}}
                                    </p>
                                </template>
                            </label>
                        </div>
                    </li>

                </template>
                <li v-else class="list_item_li no_text_li mt-2">
                    No Brand selected or No links found.
                </li>

            </template>

        </ul>
    </b-dropdown>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import {searchBioURL, searchLinksURL} from '../../../../config/api'
import axios from 'axios'
import { DARK_PURPLE_LOADER_COLOR } from '../../../../common/constants'

export default ({
  data () {
    return {
      links: [],
      search_link: '',
      loader: false,
      search_requests: [],
      page: 1,
      color: DARK_PURPLE_LOADER_COLOR,
      size: '12px'
    }
  },
  computed: {
    ...mapGetters(['getAnalyticsFilters']),
    getAnalyticsFilteredLinks () {
      return this.links
    }
  },
  methods: {
    async searchLinksDirectly (value) {
      let res = await this.$store.dispatch('searchBioLinks', {
        brand_id: this.getAnalyticsFilters.brand._id,
        page: 1,
        value
      })
      if (res && res.data.status) {
        this.page = res.data.data.current_page
        this.links = res.data.data.data
      }
    },
    getAnalyticsLinkSearch: debounce(async function (key) {
      this.loader = true
      await this.searchLinksDirectly(key)
      this.loader = false
    }, 250)
  }
})
</script>
