<template>
  <b-dropdown :disabled="!getAnalyticsFilters.campaign._id" right ref="dropdown"
              class="ml-2 radio_dropdown dropdown-menu-right default_style_dropdown test_css"
              style="min-width: 200px !important;"
              :no-caret="true">
    <!--d-flex align-items-center-->
    <div class="text-center dropdown_header  rounded_shape d-flex align-items-center"
         slot="button-content">
      <div class="text">
        <span
            v-if="getAnalyticsFilters.link.shorten_url">{{ limitTextLength(getAnalyticsFilters.link.shorten_url, 20) }}</span>
        <span v-else>Select Link</span>

      </div>
      <span class="arrow_icon ml-auto">
                                                <i class=" fal fa-angle-down ml-3"></i>
                                            </span>
    </div>
    <ul class="clear inner custom_links_dropdown">

      <div class="search_input_block no_animation ml-2 mr-2 ">
        <input class="full-width" style="font-size:13px;" type="text" v-model="search_link"
               placeholder="Search by URL/SLUG"
               @keyup="getAnalyticsLinkSearch(search_link)">

        <i class="fal fa-search"></i>
      </div>

      <template>
        <template v-if="loader">
          <clip-loader :size="size" :color="color"></clip-loader>
        </template>
        <template v-if="getAnalyticsFilteredLinks.length > 0 && !loader">
          <li class="list_item_li" v-for="link in getAnalyticsFilteredLinks">

            <div class="radio_input d-flex justify-content-between " @click="selectItem(link)">
              <!--                            <input type="radio" name=""-->
              <!--                                   :id="link._id" :value="link"-->
              <!--                                   v-model="getAnalyticsFilters.link"-->
              <!--                                   class="hide">-->

              <label :for="link._id" class="radio_right">
                <template v-if="link.campaign.custom_domain">
                  <p class="">
                                        <span
                                            v-if="link.information && link.information.meta && link.information.meta.title">
                                            {{ limitTextLength(link.information.meta.title, 20) }}
                                            <br>
                                        </span>
                    <span class="break_text">
                                            {{ link.campaign.custom_domain.url }}/{{ link.shorten_url }}
                                        </span>

                  </p>
                </template>
                <template v-else>

                  <p class="">
                                        <span
                                            v-if="link.information && link.information.meta && link.information.meta.title">
                                            {{ limitTextLength(link.information.meta.title, 20) }}
                                            <br>
                                        </span>
                    {{ link.domain.url }}{{ link.shorten_url }}</p>
                </template>
              </label>

              <i class="far fa-check-circle checked" v-if="link._id == getAnalyticsFilters.link._id"></i>
              <i class="far fa-circle" v-else></i>
            </div>
          </li>

        </template>
        <li v-else class="list_item_li no_text_li mt-2">
          No Brand/Campaign selected or No links found.
        </li>

      </template>

    </ul>
  </b-dropdown>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import { searchLinksURL } from '../../../../config/api'
import axios from 'axios'
import { DARK_PURPLE_LOADER_COLOR } from '../../../../common/constants'

export default ({
  data () {
    return {
      links: [],
      search_link: '',
      loader: false,
      search_requests: [],
      page: 1,
      color: DARK_PURPLE_LOADER_COLOR,
      size: '12px'
    }
  },
  created () {
    this.searchLinksDirectly()
  },
  computed: {
    ...mapGetters(['getAnalyticsFilters']),
    getAnalyticsFilteredLinks () {
      return this.links
    }

  },
  watch: {
  },

  methods: {
    async searchLinksDirectly (value) {
      let res = await this.$store.dispatch('searchLinks', {
        campaign_id: this.getAnalyticsFilters.campaign._id,
        page: 1,
        value
      })
      if (res && res.data.status) {
        this.page = res.data.data.current_page
        this.links = res.data.data.data
      }
    },
    selectItem (link) {
      this.getAnalyticsFilters.link = link
      this.$refs.dropdown.hide()
    },
    getAnalyticsLinkSearch: debounce(async function (key) {
      this.loader = true
      await this.searchLinksDirectly(key)
      this.loader = false
    }, 250)
  }
})
</script>
<style lang="less" scoped>
.break_text {
  width: 175px;
  word-break: break-all;
}

.custom_links_dropdown {
  &:focus {
    outline: none;
  }
}
.test_css .dropdown-menu {
  width: 360px
}
.radio_input {
  padding: 7px 15px;

  .avatar {
    .brand_img {
      width: 20px;
      height: 20px;
      border-radius: 3px;
    }
  }

  i {
    margin-top: 7px;
  }
  .checked {
    color: #2dd678;
  }
}
</style>
